import React from 'react';
import {Link} from "react-scroll";

const StickyNavbar = () => {
    const offset = -80;
    return (
        <header className={"nav"}>
            <nav className={"nav__container__actions"}>
                <ul>
                    <strong>Hostvoid</strong>
                    <li>
                        <Link activeClass="active" to="about" spy={true} smooth={true} offset={offset}>
                            ABOUT
                        </Link>
                    </li>
                    <li>
                        <Link activeClass="active" to="articles" spy={true} smooth={true} offset={offset}>
                            ARTICLES
                        </Link>
                    </li>
                </ul>
            </nav>
        </header>
    );
};

export default StickyNavbar;