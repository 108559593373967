import React from 'react';
import './css/shadows.scss'
import './css/main.scss'

const Section = ({ name, children }) => {
    return (
        <section id={name}>
            {children}
        </section>
    );
};

export default Section;