import React from 'react';
import shadows from './css/shadows.scss'
import Image from "./Image";

const Article = ({title, image, author, date, link, children}) => {
    return (
        <article className={'article shadow-4'}>
            {image != null &&
                <Image src={image} className={'articleImage'}/>
            }
            {title != null &&
                <header>
                    <h3>{title}</h3>
                </header>
            }
            {children != null &&
                <main>
                    {children}
                </main>
            }
            {author != null &&
                <footer>{author}: {date}</footer>
            }
        </article>
    );
};

export default Article;