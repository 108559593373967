import React from 'react';
import './css/shadows.scss'
import './css/main.scss'

const Image = ({src, ...props}) => {
    return (
        <img src={src} {...props}/>
    );
};

export default Image;