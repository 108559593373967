import React, {useCallback, useState} from "react";

import Particles from "react-particles";
import {loadSlim} from "tsparticles-slim";

import './App.scss';

import StickyNavbar from "./components/StickyNavbar";
import Footer from "./components/Footer";
import Lead from "./components/Lead";
import Article from "./components/Article";
import Section from "./components/Section";
import Grid from "./components/Grid";
import Ads from "./components/Ads";

function App() {
    const particlesInit = useCallback(async engine => {
        console.log(engine);
        await loadSlim(engine);
    }, []);

    const particlesLoaded = useCallback(async container => {
        await console.log(container);
    }, []);

    return (<div className={"content-wrapper"}>
        <StickyNavbar></StickyNavbar>
        <main className={'content'}>
            <Section name="about">
                <h2>ABOUT</h2>
                <Article>
                    <p>I am a web developer tying to get some experience with react</p>
                </Article>
            </Section>
            <Section name="ad1">
                <Ads zoneId="2" reviveId="69f668c0a643da2a20fdc6812865f212" />
            </Section>
            <Section name="articles">
                <h2>ARTICLES</h2>
                <Grid>
                    <Article
                        image={"https://media.discordapp.net/attachments/1096526646401577103/1108494484867649587/00019-3924565118.png"}
                        title={"Discord"}></Article>
                    <Article
                        image={"https://cdn.hostvoid.net/attachments/1096526646401577103/1109138828276539392/00020-1633860054.png"}
                        title={"CDN"}></Article>
                </Grid>
            </Section>
            <Section name="ad2">
                <Ads zoneId="3" reviveId="69f668c0a643da2a20fdc6812865f212" />
            </Section>
        </main>
        <Footer/>
        <Particles id="tsparticles"
                   options={{
                       fpsLimit: 120,
                       interactivity: {
                           events: {
                               onClick: {
                                   enable: true,
                                   mode: "push",
                               },
                               onHover: {
                                   enable: true,
                                   mode: "repulse",
                               },
                               resize: true,
                           },
                           modes: {
                               push: {
                                   quantity: 4,
                               },
                               repulse: {
                                   distance: 200,
                                   duration: 0.4,
                               },
                           },
                       },
                       particles: {
                           color: {
                               value: "#ffffff",
                           },
                           links: {
                               color: "#ffffff",
                               distance: 150,
                               enable: true,
                               opacity: 0.5,
                               width: 1,
                           },
                           move: {
                               direction: "none",
                               enable: true,
                               outModes: {
                                   default: "bounce",
                               },
                               random: false,
                               speed: 1,
                               straight: false,
                           },
                           number: {
                               density: {
                                   enable: true,
                                   area: 800,
                               },
                               value: 80,
                           },
                           opacity: {
                               value: 0.5,
                           },
                           shape: {
                               type: "circle",
                           },
                           size: {
                               value: {min: 1, max: 5},
                           },
                       },
                       detectRetina: true,
                       index: 1
                   }}
                   init={particlesInit} loaded={particlesLoaded}
        />
    </div>);
}

export default App;
