import {Link} from "react-scroll";
import React, {useEffect} from "react";


const Ads = (props) => {
    const { zoneId, reviveId } = props;

    useEffect(() => {

        try {
            window.reviveAsync = window.reviveAsync || {};
            window.reviveAsync[reviveId].apply(window.reviveAsync[reviveId].detect());
            console.log(reviveId)
        }

        catch (e) {

        }

    },[]);

    return (
        <ins className="adsbyhost" data-revive-zoneid={zoneId} data-revive-id={reviveId}></ins>
    );
};

export default Ads;