import React from 'react';

const Grid = ({children}) => {
    return (
        <div className={'grid'}>
            {children}
        </div>
    );
};

export default Grid;