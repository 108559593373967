
import { animateScroll as scroll } from 'react-scroll'

const Footer = () => {
    let scrollToTop = () => {
        scroll.scrollToTop();
    }

    return (
        <footer className={'footer'}>
            <strike>Hostvoid test page</strike>
            <hr />
            <div className={'row footer-container'}>
                <div className='column'></div>
                <div className='column'></div>
                <div className='column'></div>
                <div className='column'>
                    <p>Author: Admin</p>
                    <a href="mailto:junk@hostvoid.net">junk@hostvoid.net</a>
                </div>
            </div>
            <a onClick={scrollToTop}>To the top!</a>
        </footer>
    );
};


export default Footer;